import { useEffect, useState } from "react"

import { useMount } from "./useMount"

/**
 * Reactive media query hook that returns the truthy value of the media query.
 *
 * @param {string} query
 * @returns {boolean} boolean value of the query
 *
 * @see https://react-hooks-library.vercel.app/core/useMediaQuery
 */

export function useMediaQuery(query, iniitalValue = false) {
  const [matches, setMatches] = useState(iniitalValue)

  useMount(() => {
    setMatches(window.matchMedia(query).matches)
  })

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)
    const handler = (event) => {
      setMatches(event.matches)
    }

    if ("addEventListener" in mediaQuery) {
      mediaQuery.addEventListener("change", handler)
    } else mediaQuery.addListener(handler)

    return () => {
      if ("removeEventListener" in mediaQuery) {
        mediaQuery.removeEventListener("change", handler)
      } else mediaQuery.removeListener(handler)
    }
  }, [query])

  return matches
}
