import {
  isMobile as _isMobile,
  isMobileOnly as _isMobileOnly,
  isTablet as _isTablet,
} from "react-device-detect"
import { useMediaQuery } from "./useMediaQuery"

// return truthy value for media queries
const useDeviceMedia = (props) => {
  // default min max
  const { min, max } = props || { min: "0px", max: "0px" }

  const isMobile = useMediaQuery("(max-width: 743px)", _isMobileOnly)

  const isTablet = useMediaQuery("(max-width: 1199px)", _isMobile)
  const isTabletOnly = useMediaQuery(
    "(min-width: 744px) and (max-width: 1199px)",
    _isTablet
  )

  const largeDevice = useMediaQuery("(min-width: 1280px)")

  const isLargeScreen = useMediaQuery("(max-width: 1399px)")
  const isLargeScreenOnly = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1399px)"
  )

  const isXLargeScreen = useMediaQuery("(max-width: 1599px)")
  const isXLargeScreenOnly = useMediaQuery(
    "(min-width: 1400px) and (max-width: 1599px)"
  )
  const isDesktopOnly = useMediaQuery("(min-width: 1600px)")
  const isCustomMin = useMediaQuery(`(min-width: ${min})`)
  const isCustomMax = useMediaQuery(`(max-width: ${max})`)

  return {
    isMobile,
    isTablet,
    isTabletOnly,
    isLargeScreen,
    isLargeScreenOnly,
    isXLargeScreen,
    isXLargeScreenOnly,
    isDesktopOnly,
    isCustomMin,
    isCustomMax,
    largeDevice,
  }
}

export default useDeviceMedia
