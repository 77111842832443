const buyURL = "/buy/"
const sellURL = "/sell/"
const rentURL = "/rent/"
const offPlanURL = "/off-plan/"
const servicesURL = "/property-services/"
const aboutURL = "/about/"
const propertiesURL = "/properties/"
const commpropertiesURL = "/commercial-properties/"
const offplanResultsURL = "/off-plan-projects/"
const newsUrl = "/why-baytify/property-news/"
const guidesUrl = "/insights/guides/"

module.exports = {
  newsUrl,
  sitemapURL: "/sitemap/",
  privacyPolicyURL: "/privacy-policy/",
  termsAndConditionsURL: "/terms-and-conditions/",
  cookiePolicyURL: "/cookie-policy/",
  propertiesURL,
  propertiesSaleURL: `${propertiesURL}for-sale/`,
  propertiesRentURL: `${propertiesURL}for-rent/`,
  commpropertiesURL,
  commpropertiesSaleURL: `${commpropertiesURL}for-sale/`,
  commpropertiesRentURL: `${commpropertiesURL}for-rent/`,
  propertyDetailSaleURL: "/property-for-sale/",
  propertyDetailRentURL: "/property-for-rent/",
  commercialpropertyDetailSaleURL: "/commercial-property-for-sale/",
  commercialpropertyDetailRentURL: "/commercial-property-for-rent/",
  homePageURL: "/",
  buyURL,
  whyBuyWithUsURL: `${buyURL}why-buy-property-with-us/`,
  mortgageCalculatorURL: `${buyURL}mortgage-calculator/`,
  guidesBuyURL: `${buyURL}property-buying-guide/`,
  sellURL,
  sellYourPropertyURL: `${sellURL}sell-your-property/`,
  whySellWithUsURL: `${sellURL}why-sell-property-with-us/`,
  guidesSellURL: `${sellURL}property-selling-guide/`,
  rentURL,
  whyRentWithUsURL: `${rentURL}why-rent-property-with-us/`,
  rentalYieldCalculatorURL: `${rentURL}rental-yield-calculator/`,
  guidesRentURL: `${rentURL}property-renting-guide/`,
  offPlanURL,
  offplanResultsURL,
  offplanResultsSaleURL: `${offplanResultsURL}for-sale/`,
  offplanDetailURL: "/off-plan-project/",
  whyOffPlanWithUsURL: `${offPlanURL}why-buy-off-plan-property-with-us/`,
  mortgageAndRentalYieldCalculatorsURL: `${offPlanURL}mortgage-and-rental-yield-calculators/`,
  guidesOffPlanURL: `${offPlanURL}property-guide/`,
  servicesURL,
  leasingURL: `${servicesURL}leasing/`,
  mortgagesURL: `${servicesURL}mortgages/`,
  holidayHomesURL: `${servicesURL}holiday-homes/`,
  conveyancingURL: `${servicesURL}conveyancing/`,
  propertySnaggingURL: `${servicesURL}property-snagging/`,
  secondCitizenshipURL: `${servicesURL}second-citizenship/`,
  forexServicesURL: `${servicesURL}forex-services/`,
  partialOwnershipURL: `${servicesURL}partial-ownership/`,
  aboutURL,
  ourAwardsURL: `${aboutURL}our-awards/`,
  teamsURL: `${aboutURL}meet-the-team/`,
  reviewsURL: `${aboutURL}reviews/`,
  philanthropyURL: `${aboutURL}philanthropy/`,
  careersURL: `${aboutURL}careers/`,
  sendCVURL: "/send-us-your-cv/",
  communitiesURL: `${aboutURL}communities/`,
  newsAndInsightURL: `${aboutURL}news-and-insight/`,
  bookAViewingURL: "/book-a-viewing/",
  listYourPropertyURL: "/list-your-property/",
  contactURL: "/contact/",
  guidesUrl,
}
