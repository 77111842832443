import loadable from "@loadable/component"
import dateFormat from "dateformat"
import { Link } from "gatsby"
import React, { useState } from "react"
import { Container, Modal } from "react-bootstrap"
import NewsletterFormFields from "../../../static/forms/newsletter_side_form.json"
import RCFormFields from "../../../static/forms/request_a_callback_form.json"
import TeamFormFields from "../../../static/forms/team_form.json"
import { PageLinks } from "../../common/site/page-static-links"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import noImage from "../../images/noImage.png"
import StaticShare from "../StaticShare/StaticShare"
import Whatsapp from "../Whatsapp/Whatsapp"
import "./NewsInformation.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const DefaultForm = loadable(() => import("../forms/default-form-layout"))

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const NewsInformation = ({ newsData, moduleData, globalContent }) => {
  const newsItems = moduleData?.list_item

  const [email, setEmail] = useState("")
  const [error, setError] = useState("")

  const { largeDevice, isMobile } = useDeviceMedia()

  const handleSubmit = () => {
    alert("form not integrated yet")
  }
  const handleEmail = () => {
    // console.log("pop")
  }
  const pageUrl = typeof window !== "undefined" && window.location.href
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }
  const [showRC, setShowRC] = useState(false)
  const handleModalRC = () => {
    setShowRC(true)
  }

  const closeModalRC = () => {
    setShowRC(false)
  }
  return (
    <div className="news-info-wrapper">
      <Container>
        <div className="news-info-module">
          <div className="news-info-header">
            <h1>{newsData?.title}</h1>
            <div className="area-with-team">
              {newsData?.date != null &&
                <>
                  <p>{dateFormat(newsData?.date, "dd mmmm yy")}</p>
                  <span>/</span>
                </> 
              }
              <p>{newsData?.area_name ? newsData?.area_name : "Dubai"}</p>
              {newsData?.member?.name && <span>/</span>}
              {newsData?.member?.name &&
              <p className="team-name">
                {" "}
                <Link to={`/${PageLinks.team}/${newsData?.member?.slug}/`}>
                  {newsData?.member?.name}
                </Link>
              </p>}
            </div>
          </div>
          <div className="news-split-sections">
            <div className="news_section-container">
              {newsItems?.length > 0 && (
                <div className="news-list-items">
                  {newsItems?.map((item) => {
                    return (
                      <div className="news_content-list" key={item?.image?.url}>
                        <div className="image-section">
                          <img src={item?.image?.url} alt="pop" />
                        </div>
                        <div className="content-section">
                          <p className="description">
                            <ContentModule
                              Content={item.content?.data?.content}
                            />
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              <div className="horizontal-line" />
              <StaticShare />
            </div>
            {largeDevice && (
              <div>
                <div className="news-team-largedevice">
                  <div className="news-team-block">
                    <h3 className="title">
                      {globalContent?.node?.news_team_title}
                    </h3>
                    <p className="desc">
                      <ContentModule
                        Content={
                          globalContent?.node?.new_team_content?.data
                            ?.new_team_content
                        }
                      />
                    </p>
                    <div className="cta-section">
                      <a
                        className="primary-btn"
                        // to="/contact/general-enquiry/"
                        href="javascript:void(0)"
                        onClick={() => handleModalRC()}

                      >
                        <span>
                          <i className="icon black-call-icon" />
                        </span>
                        <span>Request a call back</span>
                      </a>
                      <div className="btn-holder">
                        {/* <a href="javascript:void(0)">
                          <span>
                            {" "}
                            <i className="icon black-mail-icon" />
                          </span>
                          <span className="text-value">Email</span>
                        </a> */}
                        <Whatsapp />
                      </div>
                    </div>
                    {newsData?.member &&
                    <div className="team-member">
                      <div className="image-section">
                        {newsData?.member?.image ? (
                          // <img
                          //   src={newsData?.member?.image?.url}
                          //   alt={newsData?.member?.name}
                          // />
                          <Link to={`/${PageLinks.team}/${newsData?.member?.slug}/`}>
                          <ImageModule
                            ImageSrc={newsData?.member?.image}
                            altText="negotiator_image"
                            imagetransforms={
                              newsData?.member?.imagetransforms
                                ?.image_Transforms
                            }
                            imagename="team.image.propdetails_image"
                            renderer=""
                            strapi_id={newsData?.member?.strapi_id}
                            classNames="img-fluid"
                          />
                          </Link>
                        ) : (
                          <Link to={`/${PageLinks.team}/${newsData?.member?.slug}/`}>
                          <img src={noImage} alt="No Image" />
                          </Link>
                        )}
                      </div>
                      <div className="team-info">
                        <h4 className="name"><Link to={`/${PageLinks.team}/${newsData?.member?.slug}/`}>{newsData?.member?.name}</Link></h4>
                        <p className="designation">
                          {newsData?.member?.designation}
                        </p>
                        <div className="id-num">
                          
                          {newsData?.member?.brn_number &&<p>BRN No: {newsData?.member?.brn_number}</p>}
                          <span>/</span>
                          <p>
                            <a
                              href="javascript:void(0)"
                              onClick={() => handleModal()}
                            >
                              Email
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="news-subscribe-block">
                    <div className="suscription-data">
                      <h3 className="sub-title">
                        {globalContent?.node?.news_inbox_title}
                      </h3>
                      <p className="sub-content">
                        <ContentModule
                          Content={
                            globalContent?.node?.news_inbox_content?.data
                              ?.news_inbox_content
                          }
                        />
                      </p>
                      <div className="form-holder">
                        {/* <Form onSubmit={handleSubmit}>
                          <Form.Group
                            className={`subscribe-form_grp ${
                              error ? "error-field" : ""
                            }`}
                          >
                            <Form.Control
                              className=""
                              value={email}
                              placeholder="Email Address"
                              controlId="formPlaintextEmail"
                              onChange={handleEmail}
                              pattern="^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$"
                              autocomplete="off"
                            />
                            <Button className="btn_footer" type="submit">
                              Subscribe
                            </Button>
                          </Form.Group>
                        </Form> */}
                        <DefaultForm
                          fields={NewsletterFormFields}
                          classname="subscribe-form_grp"
                          // submit="arrow"
                        />
                      </div>
                      {/* <p className="terms-and-privacy">
                        By clicking Subscribe, you agree to our
                        <Link href="/terms-and-conditions/">Terms </Link>
                        <span>
                          &<Link href="/privacy-policy/"> Privacy Policy</Link>.
                        </span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {!largeDevice && (
            <div className="news-team-smalldevice">
              <div className="news-team-block">
                <h3 className="title">
                  {globalContent?.node?.news_team_title}
                </h3>
                <p className="desc">
                  <ContentModule
                    Content={
                      globalContent?.node?.new_team_content?.data
                        ?.new_team_content
                    }
                  />
                </p>
                <div className="team-member">
                  <div className="image-section">
                    {newsData?.member?.image ? (
                      // <img
                      //   src={newsData?.team_member?.image?.url}
                      //   alt={newsData?.team_member?.name}
                      // />
                      <Link to={`/${PageLinks.team}/${newsData?.member?.slug}/`}>
                      <ImageModule
                        ImageSrc={newsData?.member?.image}
                        altText="negotiator_image"
                        imagetransforms={
                          newsData?.member?.imagetransforms?.image_Transforms
                        }
                        imagename="team.image.propdetails_image"
                        renderer=""
                        strapi_id={newsData?.member?.strapi_id}
                        classNames="img-fluid"
                      /></Link>
                    ) : (
                      <Link to={`/${PageLinks.team}/${newsData?.member?.slug}/`}><img src={noImage} alt="No Image" /></Link>
                    )}
                  </div>
                  <div className="team-info">
                    <h4 className="name"><Link to={`/${PageLinks.team}/${newsData?.member?.slug}/`}>{newsData?.member?.name}</Link></h4>
                    <p className="designation">
                      {newsData?.member?.designation}
                    </p>
                    <div className="id-num">
                    {newsData?.member?.brn_number &&
                      <p>BRN No: {newsData?.member?.brn_number}</p>}
                      <span>/</span>
                      <p>
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleModal()}
                        >
                          Email
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="news-subscribe-block">
                <div className="suscription-data">
                  <h3 className="sub-title">
                    {globalContent?.node?.news_inbox_title}
                  </h3>
                  <p className="sub-content">
                    <ContentModule
                      Content={
                        globalContent?.node?.news_inbox_content?.data
                          ?.news_inbox_content
                      }
                    />
                  </p>
                  <div className="form-holder">
                    {/* <Form onSubmit={handleSubmit}>
                      <Form.Group
                        className={`subscribe-form_grp ${
                          error ? "error-field" : ""
                        }`}
                      >
                        <Form.Control
                          className=""
                          value={email}
                          placeholder="Email Address"
                          controlId="formPlaintextEmail"
                          onChange={handleEmail}
                          pattern="^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$"
                          autocomplete="off"
                        />
                        <Button className="btn_footer" type="submit">
                          Subscribe
                        </Button>
                      </Form.Group>
                    </Form> */}
                    <DefaultForm
                      fields={NewsletterFormFields}
                      classname="subscribe-form_grp"
                      // submit="arrow"
                    />
                  </div>
                  {/* <p className="terms-and-privacy">
                    By clicking Submit, you agree to our{" "}
                    <a target="_blank" href="/terms-and-conditions/">
                      Terms & Conditions{" "}
                    </a>
                    <span>
                      and
                      <a target="_blank" x href="/privacy-policy/">
                        {" "}
                        Privacy Policy{" "}
                      </a>
                      .
                    </span>
                  </p> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
      {!largeDevice && (
        <div className="cta-section-mobile">
          <div className="cta_mobile_news">
            <a 
                                    href="javascript:void(0)"
                                    onClick={() => handleModalRC()}
            
            // to="/contact/general-enquiry/"
            >
              <span>
                <i className="icon black-call-icon" />
              </span>

              <span>Request a call back</span>
            </a>
            {/* <a className="secondary-btn" href="javascript:void(0)">
              <span>
                {" "}
                <i className="icon black-mail-icon" />
              </span>
              {!isMobile && <span className="text-value">Email</span>}
            </a> */}
            <Whatsapp class="secondary-btn" />
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal team-form"
        id="filter-modal"
        // fullscreen={"xl-down"}
        backdrop="static"
      >
        <button
          className="cancel-button"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <h2>Email to {newsData?.member?.name}</h2>
            <DefaultForm
              fields={TeamFormFields}
              formtagclassname="team-form"
              to_email_id={newsData?.member?.email}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
                  show={showRC}
                  onHide={() => setShowRC(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="more-filters-modal team-form"
                  id="filter-modal"
                  // fullscreen={"xl-down"}
                  backdrop="static"
                >
                  <button
                    className="cancel-button"
                    type="button"
                    onClick={() => closeModalRC()}
                  >
                    <i className="icon black-cancel" />
                  </button>
                  <Modal.Body>
                    <div className="modal-body-container">
                      <h2>Request a call back</h2>
                      <DefaultForm
                        fields={RCFormFields}
                        formtagclassname="team-form"
                        // to_email_id={}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
    </div>
  )
}

export default NewsInformation
