import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { getWhatsAppURL } from "../../common/site/config"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const Whatsapp = (props) => {
  const { largeDevice, isMobile } = useDeviceMedia()
  const data = useStaticQuery(graphql`
    query {
      strapiSiteConfig {
        whatsapp
      }
    }
  `)
  return (
    <a
      className={props?.class}
      href={`${getWhatsAppURL(data?.strapiSiteConfig?.whatsapp)}`}
      target="_blank"
      rel="noreferrer"
    >
      <span>
        {" "}
        <i className="icon green-whatsapp" />
      </span>
      {!isMobile && <span className="text-value">WhatsApp</span>}
    </a>
  )
}

export default Whatsapp
