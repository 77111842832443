import { graphql } from "gatsby"
import React from "react"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import NewsInformation from "../components/NewsInformation/NewsInformation"
import NewsSliderCard from "../components/NewsSliderCard/NewsSliderCard"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NewsDetailTemplate = ({ data }) => {
  const PageData = data?.strapiBlog
  const globalContent =
    data?.allStrapiGlobalContent?.edges?.length > 0 &&
    data?.allStrapiGlobalContent?.edges[0]
  const blogList = data?.allStrapiBlog?.edges

  return (
    <Layout popularSearch="Generic pages">
      <div className="layout-padding-top">
        <BreadcrumbModule
          subparentlabel={PageLinks?.newslabel}
          subparentlink={PageLinks?.news}
          title={PageData?.title}
          parentlabel={PageLinks?.whybatifylabel}
          parentlink={PageLinks?.whybatify}
        />
        {PageData?.blog_content?.map((module) => {
          return (
            <div key={module.id}>
              {module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_TEXT_AND_IMAGE" && (
                <NewsInformation
                  newsData={PageData}
                  moduleData={module}
                  globalContent={globalContent}
                />
              )}
            </div>
          )
        })}

        <NewsSliderCard blogData={PageData} blogList={blogList} />
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiBlog

  const curUrl = typeof window !== "undefined" ? window.location.href : ""

  const ldJson={
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
    "@type": "WebPage",
    "@id": curUrl
    },
    headline: "Article Heading",
    image: [
    PageData?.tile_image?.url? PageData?.tile_image?.url:""
    ],
    datePublished: PageData?.date,
    dateModified: PageData?.date,
    author: {
    "@type": "Person",
    name: PageData?.member?.name
    },
    publisher: {
    "@type": "Organization",
    name: "Baytify Real Estate Agents in Dubai",
    logo: {
    "@type": "ImageObject",
    url: "https://ggfx-baytify.s3.eu-west-2.amazonaws.com/i.prod/baytify_email_logo_5da2bf6ad8.png",
    }
    },
    description: `Read about ${PageData.title} here and subscribe to our newsletter to stay up-to-date about everything going on at Baytify.`
    }
    
  return (
    <Seo
      title={`${PageData.title}`}
      description={`Read about ${PageData.title} here and subscribe to our newsletter to stay up-to-date about everything going on at Baytify.`}
    >
        <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      /> 
    </Seo>
  )
}

export default NewsDetailTemplate

export const query = graphql`
  query ($page_id: Int) {
    strapiBlog(strapi_id: { eq: $page_id }) {
      ...BlogFragment

      blog_content {
        ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_AND_IMAGE {
          __typename
          id
          list_item {
            id
            content {
              data {
                content
              }
            }
            image {
              url
            }
            strapi_id
          }
        }
      }
    }

    allStrapiBlog(filter: { publish: { eq: true } }) {
      edges {
        node {
          ...BlogFragment
        }
      }
    }

    allStrapiGlobalContent {
      edges {
        node {
          new_team_content {
            data {
              new_team_content
            }
          }
          news_team_title
          news_inbox_title
          news_inbox_content {
            data {
              news_inbox_content
            }
          }
        }
      }
    }
  }
`
