import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./CustomSlider.scss"

export const CustomSlider = ({
  children,
  className,
  slidecount,
  rowcount,
  mobrowcount,
  arrows,
  showFinite,
  slideSpeed,
}) => {
  const options = {
    dots: false,
    arrows:true,
    infinite: showFinite?false:true,
    speed: slideSpeed?200:800,
    slidesToShow: slidecount,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: true,
    rows: rowcount || 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: slidecount,
          slidesToScroll: 1,
          infinite: showFinite?false:true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: slidecount,
          slidesToScroll: 1,
          infinite: showFinite?false:true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: slidecount,
          slidesToScroll: 1,
          infinite: showFinite?false:true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          rows: mobrowcount || 1,
        },
      },
    ],
  }

  return (
    <Slider className={`custom-slider ${className}`} {...options}>
      {children}
    </Slider>
  )
}

export default CustomSlider
